import "core-js/modules/es.array.push.js";
import { useStore } from 'vuex';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import { computed, onBeforeMount, reactive, toRefs } from 'vue';
import { ElImage, ElRow, ElCol } from 'element-plus';
export default {
  name: 'Page403',
  components: {
    ElImage,
    ElRow,
    ElCol
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      timeout: 5,
      oops: '抱歉!',
      headline: '您没有操作角色...',
      info: '当前帐号没有操作角色,请联系管理员。',
      btn: '返回',
      timer: 0
    });
    onBeforeRouteLeave((to, from, next) => {
      clearInterval(state.timer);
      next();
    });
    onBeforeMount(() => {
      timeChange();
    });
    const timeChange = () => {
      state.timer = setInterval(() => {
        if (state.timeout) {
          state.timeout--;
        } else {
          router.push('/');
          clearInterval(state.timer);
        }
      }, 1000);
    };
    return {
      ...toRefs(state),
      timeChange
    };
  }
};